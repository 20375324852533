import React, { useEffect } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import {
  Button,
  DialogContent,
  FormControl, FormHelperText,
  InputAdornment, InputLabel, MenuItem, Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Alert } from "../styled/GlobalStyled";
import { baseAxios } from "../../utils/axios";
import { COLORS } from "../../constants/base";
import 'dayjs/locale/de'
import {selectSites} from "../../redux/sites";
import getFoodCoast from "../../utils/food/getFoodCoast";

dayjs.extend(utc);

function PriceForm({ food, setFoodList, handleCloseModel, mixpanelSubmit, site }) {
  const { enqueueSnackbar } = useSnackbar();
  const { sites } = useSelector(selectSites)
  const isMultiSites = sites.length > 1
  console.log(site)

  const submit = async (values, { setErrors, setSubmitting }) => {
    const data = {
      food: values.foodId,
      value: values.price,
      site: values.site,
    };
    try {
      const responce = await baseAxios.post("api/food-costs/", data);

      setFoodList((prevValue) =>
        prevValue.map((f) =>
          f.id === food.id
            ? { ...f, price_per_kg: parseFloat(responce.data.value) }
            : f
        )
      );
    } catch (err) {
      enqueueSnackbar("Oops, something went wrong.", {
        variant: "error",
      });
      setErrors(err.responce ? err.responce.data : err.message);
    }
    setSubmitting(true);
    handleCloseModel();
  }

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    values
  } = useFormik(({
    initialValues: {
      food: food.name,
      foodId: food.id,
      price: 0,
      site: site?.id,
    },
    isInitialValid: true,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      price: Yup.number().required("Price per KG is required"),
      food: Yup.string().required("Food is required"),
      foodId: Yup.number().required("Food is required"),
      site: Yup.number().required("Site is required"),
    }),
    onSubmit: submit,
  }))

  useEffect(() => {
    getFoodCoast({ foodId: food.id, siteId: values.site })
      .then(val => setFieldValue('price', val))
  }, [values.site, food.id]);

  return (
    <DialogContent>
      <form noValidate onSubmit={handleSubmit}>
        {errors.submit && (
          <Alert mt={2} mb={3} severity="warning">
            {errors.submit}
          </Alert>
        )}
        <TextField
          fullWidth
          disabled
          variant="standard"
          type="text"
          name="food"
          label="Food"
          value={values.food}
          error={Boolean(errors.food)}
          helperText={errors.food}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: COLORS.tableBlack,
            },
          }}
          my={4}
        />

        <TextField
          fullWidth
          variant="standard"
          type="text"
          name="price"
          label="Custom Price (Per KG)"
          value={values.price}
          error={Boolean(errors.price)}
          helperText={errors.price}
          onBlur={handleBlur}
          onChange={handleChange}
          my={4}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">€</InputAdornment>
            ),
          }}
        />
        {isMultiSites && (
          <FormControl fullWidth variant="standard">
            <InputLabel id="site">Site</InputLabel>
            <Select
              id="site"
              name="site"
              value={values.site}
              onChange={handleChange}
              error={!!errors.site}
            >
              {sites.map(site => (
                <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
              ))}
            </Select>
            {errors.site && (
              <FormHelperText error>
                {errors.site}
              </FormHelperText>
            )}
          </FormControl>
        )}
        <Button
          sx={{ height: "50px", marginTop: "1rem" }}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          onClick={mixpanelSubmit}
        >
          Update Food
        </Button>
      </form>
    </DialogContent>
  );
}

PriceForm.propTypes = {
  food: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price_per_kg: PropTypes.number.isRequired,
    price_date: PropTypes.string,
  }),
  setFoodList: PropTypes.func.isRequired,
  handleCloseModel: PropTypes.func.isRequired,
  mixpanelSubmit: PropTypes.func.isRequired,
};

PriceForm.defaultProps = {
  food: null,
};

export default PriceForm;
