import React from 'react';
import { FormControlLabel } from "@mui/material";
import {CustomCheckbox} from "../../components/styled/GlobalStyled";
import {isFunc} from "../../utils/funcs";

const PRIVACY_LINK = 'https://www.positivecarbon.org/privacy-policy'

const Label = () => (
  <div>I have read and agree to the <a href={PRIVACY_LINK} target="_blank">Privacy Policy</a></div>
)

const Terms = ({ onChange }) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const handleChange = (e) => {
    setIsChecked(e.target.checked);
    isFunc(onChange) && onChange(e.target.checked);
  }
  return (
    <FormControlLabel
      checked={isChecked}
      onChange={handleChange}
      sx={{ marginLeft: 0 }}
      control={
        <CustomCheckbox
          sx={{ padding: "0", marginRight: "10px", marginLeft: 0 }}
          name="trimmings"
        />
      }
      label={<Label /> }
    />
  );
};

export default Terms;