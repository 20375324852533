import React, { Fragment, memo, useMemo } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { Box, Divider, useMediaQuery } from "@mui/material";
import { useModal } from "mui-modal-provider";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { faPen, faBars, faCircleInfo, faSackDollar, faScaleBalanced, faDroplet, faEarthAmerica, faUtensils } from '@fortawesome/free-solid-svg-icons'


import { selectFilters } from "../../redux/filters";
import { getPreviousDiffDates } from "../../utils/data/prevDate";
import InsightsChartFooter from "./components/InsightsChartFooter";
import InsightsChartHeader from "./components/InsightsChartHeader";
import SimpleDialog from "../dialogs/SimpleDialog";
import PriceForm from "../foods/PriceForm";
import { formatDataQuery } from "../../utils/date/formatDataQuery";
import numberFormater from "../../utils/formaters/numberFormater";
import InsightLineChart from "./components/InsightLineChart";
import { TrimmingsIcon } from "../icons/TrimmingsIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function FoodChartCard(props) {
  const { showModal } = useModal();

  const navigate = useNavigate();

  const { filters, view } = useSelector(selectFilters);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const getValues = (id) => {
    switch (id) {
      case "price":
        return [
          {
            value: numberFormater({
              value: props.value || 0,
              fixed: isMobile ? 2 : 0,
              before: "€",
            }),
            tooltip: "Value",
            icon: faSackDollar,
          },
        ];
      case "environmental":
        return [
          {
            value: numberFormater({
              value: props.co2_captured || 0,
              fixed: isMobile ? 2 : 0,
              after: "KG",
            }),
            tooltip: "Environmental footprint",
            icon: faEarthAmerica,
          },
          {
            value: numberFormater({
              value: props.water_captured || 0,
              fixed: isMobile ? 2 : 0,
              after: "L",
            }),
            tooltip: "Water footprint",
            icon: faDroplet,
          },
        ];
      case 'weight':
        return [
          {
            value: numberFormater({
              value: props.item_weight || 0,
              fixed: isMobile ? 2 : 0,
              after: "KG",
            }),
            tooltip: "Weight",
            icon: faScaleBalanced,
          },
          undefined,
          {
            value: numberFormater({
              value: props.sum_trimmings || 0,
              fixed: isMobile ? 2 : 0,
              after: "KG",
            }),
            tooltip: "Trimmings",
            icon: faUtensils,
          },
        ]
      default:
        return [
          {
            value: numberFormater({ value: 0, fixed: 0 }),
            tooltip: "",
            Icon: Fragment,
          },
          {
            value: numberFormater({ value: 0, fixed: 0 }),
            tooltip: "",
            Icon: Fragment,
          },
        ];
    }
  };

  const values = getValues(view.display.id);

  const handleEdit = () => {
    const modalEdit = showModal(SimpleDialog, {
      title: `Edit ${props.name}`,
      maxWidth: "xs",
      fullWidth: true,
      handleClose: () => modalEdit.hide(),
    });

    modalEdit.update({
      content: (
        <PriceForm
          food={props}
          setFoodList={() => {}}
          handleCloseModel={modalEdit.hide}
          mixpanelSubmit={() => {}}
          site={filters.site[0]}
        />
      ),
    });
  };

  const handleToFeed = () => {
    const path = "/feed?";
    const params = new URLSearchParams({
      foodId: props.id,
      foodName: props.name,
      startDate: formatDataQuery(filters.startDate),
      endDate: formatDataQuery(filters.endDate),
    });
    navigate(path + params.toString());
  };

  const handeToDetail = () => {
    const p = new URLSearchParams({
      startDate: formatDataQuery(filters.startDate),
      endDate: formatDataQuery(filters.endDate),
    });
    const path = `/insights/foods/${props.id}/?${p}`;
    // mixpanelTrack(`Foods - ${props.name} - Navigate to Foods Drilldown`);
    navigate(path);
  };

  const prevDate = getPreviousDiffDates({ ...filters });

  const buttons = useMemo(
    () => [
      {
        name: "Edit",
        icon: <FontAwesomeIcon icon={faPen} />,
        onClick: handleEdit,
      },
      {
        name: "Feed",
        icon: <FontAwesomeIcon icon={faBars} />,
        onClick: handleToFeed,
      },
      {
        name: "Detail",
        icon: <FontAwesomeIcon icon={faCircleInfo} />,
        onClick: handeToDetail,
      },
    ],
    []
  );

  return (
    <Paper elevation={3} sx={{ p: "1rem 2rem" }}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={4}
      >
        <InsightsChartHeader
          withImage
          title={props.name}
          upperValue={values[0]}
          lowerValue={values[1]}
          additionalValue={values[2]}
          diffValue={
            props.prev_weight === 0
              ? 0
              : (props.item_weight / props.prev_weight) * 100
          }
          unit="kg"
          startDate={dayjs(props.prev_start_date)}
          endDate={dayjs(props.prev_end_date)}
          url={props.image_url}
        />
        {!view.condense && (
          <>
            <Divider />
            <Box style={{ height: "250px" }}>
              <InsightLineChart
                data={props.chart_data}
                width={250}
                height={250}
                unit="kg"
              />
            </Box>
            <Divider />
            <InsightsChartFooter buttons={buttons} />
          </>
        )}
      </Stack>
    </Paper>
  );
}

FoodChartCard.defaultProps = {
  focusAreasData: [],
};

FoodChartCard.propTypes = {
  identifier: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default FoodChartCard;
