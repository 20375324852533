import React, {useEffect} from 'react';
import {Divider, Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import * as Yup from 'yup';

import GoogleLoginButton from "../../components/auth/GoogleLoginButton";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import FormLayout from "../../components/auth/FormLayout";
import useAuth from "../../hooks/useAuth";
import {useFormik} from "formik";
import useIsMobile from "../../hooks/useIsMobile";
import Terms from "./Terms";

const validateSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
})
const BaseSignIn = () => {
  const { sendMagicLink, loginWithMagicLink } = useAuth()
  const navigate = useNavigate()
  const [ query ] = useSearchParams()
  const magicToken = query.get('sesame')
  const isMobile = useIsMobile()
  const [agreePrivacy, setAgreePrivacy] = React.useState(false);

  useEffect(() => {
    if(magicToken) {
      loginWithMagicLink(magicToken)
    }
  }, [magicToken]);
  const submit = ({ email }) => sendMagicLink(email)
    .then(() => navigate(`/auth/check-email/${email}`))

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: { email: "" },
    validationSchema: validateSchema,
    onSubmit: submit,
    validateOnChange: false,
  })

  useEffect(() => {
    if (isMobile) {
      navigate('/auth/sign-in-with-password')
    }
  }, [isMobile]);

  const loginWithPassword = () => {
    if (values.email) {
      return validateSchema
        .validate(values)
        .then(() => navigate(`/auth/sign-in-with-password?email=${values.email}`))
        .catch(() => navigate('/auth/sign-in-with-password'))
    }
    navigate('/auth/sign-in-with-password')
  }

  return (
    <FormLayout title='Log into your Account' headTitle="Sign In">
      <Stack spacing={4}>
        <GoogleLoginButton disabled={!agreePrivacy} />
        <Divider />
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <TextField
              label="Email"
              placeholder="email@address.com"
              InputLabelProps={{ shrink: true }}
              value={values.email}
              error={errors.email}
              name="email"
              helperText={errors.email}
              onChange={handleChange}
            />
            <Terms onChange={setAgreePrivacy}/>
            <Button onClick={loginWithPassword} variant="contained" color="secondary" type="button">Sign in with Password</Button>
            <Typography variant='small1' color="darkblue">
              <Button type="submit" disabled={!agreePrivacy} sx={{ textDecoration: 'underline' }}>Or sign in with a magic link instead</Button>
              {` for a password free sign-in`}
            </Typography>
          </Stack>
        </form>
      </Stack>
    </FormLayout>
  );
};

export default BaseSignIn;
