import React, {useEffect, useMemo } from 'react';
import {
  Divider,
  FormControl, FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import DateRangeInput from "../inputs/DateRangeInput";
import useAppSelector from "../../hooks/useAppSelector";
import {
  LABELS,
  LABELS_LABEL, METRIC_LABEL_BADGE,
  METRICS,
  METRICS_LABEL
} from "../../constants/explore";
import ReviewSelectStandard from "../inputs/ReviewSelectStandard";
import {useExploreFilters} from "./useExploreFilters";
import ExportButton from "./ExportButton";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useDispatch, useSelector} from "react-redux";
import {fetchFoods, selectFoods} from "../../redux/foods";
import {selectSites} from "../../redux/sites";
import {ALL_SITES} from "../../constants/widgets";
import {selectFocusFood} from "../../redux/profile";
import SimpleDialog from "../dialogs/SimpleDialog";
import ConfirmModal from "../../layouts/ConfirmModal";
import {useModal} from "mui-modal-provider";
import constants from '../filter/configs/constants'
import {fetchZones, selectZones} from "../../redux/zones";
import {getLabelsByMetric, validateDateRange} from "./helpers";
import useSWR from "swr";
import {events} from "../../utils/swrs/urls";
import {fetcher} from "../../utils/axios";
const FOCUS_TITLE = 'Please go to the home page to set your focus foods'

const dateRangeValidation = Yup.array()
  .test('date-validation', 'Invalid date range', (value) => {
    const { isValid } = validateDateRange(value);
    return isValid;
 })

const validation = Yup.object().shape({
  dateRange: dateRangeValidation,
  sites: Yup.array().when('metric', {
    is: (value) => value === METRICS.wastePerCover,
    then: Yup.array().min(1, 'Site is required'),
    otherwise: Yup.array() // No validation
  })
})

const Filter = ({ closeModal }) => {
  const { filters, updateFilters } = useExploreFilters()
  const { sites } = useAppSelector(selectSites)
  const { foods } = useAppSelector(selectFoods)
  const focusFood = useAppSelector(selectFocusFood)
  const { showModal } = useModal()
  const { zones } = useSelector(selectZones);
  const { values, handleChange, setFieldValue, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      metric: filters.metric,
      labels: filters.labels,
      dateRange: filters.dateRange,
      sites: filters.sites,
      food: filters.food,
      zones: filters.zones,
      event: filters.event,
    },
    validationSchema: validation,
    onSubmit: value => {
      updateFilters(value)
      if(closeModal) {
        closeModal()
      }
    },
  })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch()
  const selectedSite = values.sites[0]
  const siteFocusFood = useMemo(() => {
    if (selectedSite) {
      return sites.find(site => site.id === selectedSite.id)?.focus_food || []
    }
    return []
  }, [selectedSite?.id, sites])


  const handleDateRangeChange = value => {
    const { isValid } = validateDateRange(value)
    setFieldValue('dateRange', value)
    if (isValid) {
      dispatch(fetchFoods({ date: value }))
    }
  }

  const { data: eventList } = useSWR(
    [events.pastUpcomingEvents, {
      sites: [selectedSite?.id],
    }],
    fetcher,
    {
      fallbackData: { upcoming: [], past: [] },
      revalidateOnFocus: false,
      keepPreviousData: true,
    }
  )

  const eventAllList = useMemo(() => eventList.upcoming.concat(eventList.past), [eventList])

  useEffect(() => {
    if (values.event) {
      const eventData = eventAllList.find(({ id }) => id === values.event)
      if (eventData) {
        setFieldValue(
          'dateRange',
          [new Date(eventData.start_date), new Date(eventData.end_date)]
        )
      }
    }
  }, [values.event]);

  const showFocusModal = () => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <ConfirmModal
        onClose={() => targetModal.hide()}
        onConfirm={() => targetModal.hide()}
        confirmText={'Ok'}
        title={FOCUS_TITLE}
        closeModal={() => targetModal.hide()}
        info
      />,
    })
  }

  useEffect(() => {
    console.log('work')
    dispatch(fetchZones({ site: values.sites}))
  }, [values.sites]);

  useEffect(() => {
    setFieldValue('dateRange', filters.dateRange)
  }, [filters.dateRange]);

  useEffect(() => {
    if (values.labels === LABELS.focusFood && !(focusFood.length || siteFocusFood.length)) {
      showFocusModal()
    }
  }, [values.labels, focusFood.length, siteFocusFood.length])

  const labelOptions = useMemo(() => getLabelsByMetric(values.metric), [values.metric])

  useEffect(() => {
    if(!labelOptions.includes(values.labels)) {
      setFieldValue('labels', 'none')
    }
  }, [labelOptions]);

  const handleSiteChange = event => {
    const site = sites.filter(site => site.id === event.target.value)
    setFieldValue('sites', site)
    setFieldValue('event', '')
  }

  return (
    <Stack spacing={5}>
      <Typography variant="medium">Filters</Typography>
      <Divider />
      <form onSubmit={handleSubmit}>
        <Stack spacing={5}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="metric">Metric</InputLabel>
            <Select
              id="metric"
              name="metric"
              value={values.metric}
              onChange={handleChange}
            >
              {Object.values(METRICS).map(metric => (
                <MenuItem key={metric} value={metric}>
                  {METRICS_LABEL[metric]}
                  &nbsp;
                  {METRIC_LABEL_BADGE[metric] && <span style={{ fontStyle: 'italic' }}>{`${METRIC_LABEL_BADGE[metric]}`}</span>}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="metric">Labels</InputLabel>
            <Select
              id="labels"
              name="labels"
              value={values.labels}
              onChange={handleChange}
            >
              {labelOptions.map(label => (
                <MenuItem key={label} value={label}>{LABELS_LABEL[label]}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <DateRangeInput
            value={values.dateRange}
            outlined
            setValue={handleDateRangeChange}
            onClean={() => setFieldValue('dateRange', [])}
            label="Select Date"
            variant="outlined"
            ranges={isMobile ? [] : undefined}
            error={errors.dateRange}
          />
          <FormControl
            variant="outlined"
            fullWidth
          >
            <InputLabel id="event">Event</InputLabel>
            <Select
              id="event"
              name="event"
              value={values.event}
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem value=''>All</MenuItem>
              <ListSubheader>Upcoming</ListSubheader>
              {eventList.upcoming.map(event => (
                <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
              ))}
              <ListSubheader>Past</ListSubheader>
              {eventList.past.map(event => (
                <MenuItem key={event.id} value={event.id}>{event.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="sites">Select Site</InputLabel>
            <Select
              id="sites"
              name="sites"
              value={values.sites[0]?.id || ALL_SITES}
              onChange={handleSiteChange}
              error={!!errors.sites && touched.sites}
            >
              <MenuItem value={ALL_SITES}>All Sites</MenuItem>
              {sites.map(site => (
                <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
              ))}
            </Select>
            {errors.sites && touched.sites && (
              <FormHelperText error>{errors.sites}</FormHelperText>
            )}
          </FormControl>

          <ReviewSelectStandard
            fullWidth
            label={constants.zone.label}
            multiple
            variant="outlined"
            setValue={val => setFieldValue('zones', val)}
            value={values.zones}
            items={zones}
            placeholder="All"
          />
         <ReviewSelectStandard
           fullWidth
           label="Food"
           multiple
           variant="outlined"
           setValue={val => setFieldValue('food', val)}
           value={values.food}
           items={foods}
           placeholder="All"
           />
          <Button type="submit" fullWidth variant="contained" color="secondary">Apply Filters</Button>
        </Stack>
      </form>
      {!isMobile && (
        <>
          <Divider />
          <ExportButton />
        </>
      )}
    </Stack>
  );
};

export default Filter;
