import React, {useEffect} from 'react';
import Modal from "../../layouts/Modal";
import {
  Button,
  Divider,
  FormControl, FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from "@mui/material";
import getLabellingFoods from "../labelling/axios/getLabellingFoods";
import {useSelector} from "react-redux";
import {getSelectedSiteOrDefault, selectSites} from "../../redux/sites";
import {useFormik} from "formik";
import SelectFood from "../labelling/SelectFood";
import * as Yup from "yup";
import {handleDecimalNumbersChange} from "../../modules/venus/shared/helpers";
import dayjs from "dayjs";
import {endDateValue} from "../../utils/date/endDateValue";
import {axiosCreate} from "../../utils/events/axiosCreate";
import {useSnackbar} from "notistack";
import {CustomCheckbox} from "../styled/GlobalStyled";

const validation = Yup.object().shape({
  food: Yup.array().test('check-food', 'Required field', value => !!value?.length),
  amount: Yup.number().min(0.01).max(350).required('Required field'),
})

const AddWasteModal = ({ closeModal, onSuccess }) => {
  const [foods, setFoods] = React.useState([]);
  const site = useSelector(getSelectedSiteOrDefault())
  const { sites } = useSelector(selectSites)
  const isMultiSites = sites.length > 1
  const [foodOpen, setFoodOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = React.useState(false);

  const addWaste = (values) => {
    const device = sites.find(s => s.id === values.site)?.device_serial
    setIsLoading(true);
    const payload = {
      amount: values.amount,
      tags: ['USER LABELLED'],
      wasteDate: dayjs(endDateValue()),
      deviceId: device,
      foodDescription: values.food[0],
      status: 'SUCCESSFUL',
      trimmings: values.trimmings,
    }
      return axiosCreate(payload)
        .then(() => {
          enqueueSnackbar({ variant: "success", message: 'Event succesfully created'})
          onSuccess()
          closeModal()
        })
        .catch(() => enqueueSnackbar({ variant: 'error', message: 'Error during creating event. Try again'}))
        .finally(() => setIsLoading(false));
  }

  const { values, handleChange, handleSubmit, setFieldValue, isValid, errors, touched} = useFormik({
    initialValues: {
      site: site.id,
      amount: 0.01,
      food: [],
    },
    validationSchema: validation,
    onSubmit: addWaste,
  })

  useEffect(() => {
    getLabellingFoods({ siteId: values.site})
      .then(setFoods)
  }, [values.site])

  const handleFoodChange = food => setFieldValue('food', food)
  const handleTrimmingsChange = e => setFieldValue('trimmings', e.target.checked)
  return (
    <Modal title="Add Waste">
      <form onSubmit={handleSubmit}>
        <Stack gap={4}>
          <FormControl variant="outlined" fullWidth disabled={!isMultiSites}>
            <InputLabel id="sites">Select Site</InputLabel>
            <Select
              id="site"
              placeholder="All Sites"
              name="site"
              value={values.site}
              onChange={handleChange}
            >
              {sites.map(site => (
                <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack>
            <SelectFood
              food={values.food}
              setFood={handleFoodChange}
              options={foods}
              open={foodOpen}
              setOpen={setFoodOpen}
              variant="outlined"
            />
            {errors.food && (
              <FormHelperText error>{errors.food}</FormHelperText>
            )}
          </Stack>
          <TextField
            name='amount'
            label="Amount (KG)"
            value={values.amount}
            onChange={handleDecimalNumbersChange(setFieldValue)}
            error={touched.amount && errors.amount}
            helperText={touched.amount && errors.amount}
          />
          <FormControlLabel
            checked={values.trimmings}
            control={
              <CustomCheckbox
                sx={{ padding: "0 11px" }}
                name="trimmings"
                onChange={handleTrimmingsChange}
              />
            }
            label="Trimmings"
          />
        </Stack>
        <Modal.Footer>
          <Button disabled={isLoading} type="submit" variant="contained" color="secondary">Submit</Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddWasteModal;