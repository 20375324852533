import {ONLY_NUMBERS_REGEX, DECIMAL_NUMBERS} from "../../../constants/base";

export const handleNumbersChange = handler => event => {
  const value = event.target.value;
  const name = event.target.name;
  if (!value || ONLY_NUMBERS_REGEX.test(value)) {
    handler(name, value === '' ? value : value)
  }
}

export const handleDecimalNumbersChange = handler => event => {
  const value = event.target.value;
  const name = event.target.name;
  if (!value || DECIMAL_NUMBERS.test(value)) {
    handler(name, value === '' ? value : value)
  }
}
