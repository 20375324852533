import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Box, Card, Stack, Typography} from "@mui/material";
import Filter from "../../components/explore/Filter";
import TimeFilter from "../../components/explore/TimeFilter";
import ChartView from "../../components/explore/ChartView";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {useDispatch} from "react-redux";
import Chart from "../../components/explore/chart";
import useAppSelector from "../../hooks/useAppSelector";
import { useLocation } from "react-router-dom";
import ExportButton from "../../components/explore/ExportButton";
import {init, resetFilters, selectExplore} from "../../redux/explore";
import qs from "qs";
import CircularLoading from "../../components/progress/CircularLoading";
import {withSites} from "../../hoc/withSites";
import Page from "../../layouts/Page";
import {fetchZones} from "../../redux/zones";
import useWindowSize from "../../hooks/useWindowSize";

const Explore = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch()
  const location = useLocation()
  const { isLoading } = useAppSelector(selectExplore)
  const { height } = useWindowSize()
  const filterRef = useRef(null)
  const filtersFromQuery = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true, arrayFormat: 'indices' }),
    [location.search]
  )
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    // dispatch(resetFilters())
    dispatch(init({ params: filtersFromQuery }))
      .then(() => setIsReady(true))
  }, []);

  const maxChartHeight = useMemo(() => {
    const h = height - 260
    if (filterRef.current) {
      const filterHeight = filterRef.current.getBoundingClientRect().height
      return Math.min(h, filterHeight)
    }
    return h
  }, [filterRef.current, height])

  return (
    <CircularLoading loading={isLoading || !isReady}>
      <Page title="Explore">
        <Stack spacing={10}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="title" component="h1">Explore</Typography>
            <Stack direction="row" spacing={6} alignItems="center">
              {!isMobile && (<TimeFilter />)}
              <ChartView />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={10} height="100%">
            {!isMobile && (
              <Card ref={filterRef} sx={{ padding: '30px 25px', maxWidth: '428px', flex: '1 0 380px', minHeight: '780px'}}>
                <Filter />
              </Card>
            )}
            <Card sx={{ flex: 1, padding: '32px 25px 20px', position: 'relative', overflow: 'visible', minWidth: 0 }}>
              <Chart maxHeight={!isMobile ? maxChartHeight : undefined } />
            </Card>
          </Stack>
          {isMobile && <ExportButton />}
        </Stack>
      </Page>
    </CircularLoading>
  );
};

export default withSites(Explore);
