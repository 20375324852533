import {baseAxios} from "../axios";

export const axiosCreate = async ({
  wasteDate,
  amount,
  status,
  image,
  deviceId,
  tags,
  foodDescription,
  trimmings,
}) => {
  let date;
  if (wasteDate.$d) {
    date = wasteDate.$d.toISOString();
  } else {
    date = wasteDate;
  }

  const url = "api/events/";
  const data = {
    processed_at: date,
    labelled_at: date,
    item_weight: amount,
    raw_weight: 0,
    status,
    image_url: image,
    device_serial: deviceId,
    additional_tags: tags,
    food_description: foodDescription.id,
    food: foodDescription.food,
    trimmings,
  };
  return await baseAxios.post(url, data);
};
