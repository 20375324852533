import React from 'react';
import {ReactComponent as GoogleIcon} from '../../assets/icons/google.svg'
import Button from "@mui/material/Button";
import useAuth from "../../hooks/useAuth";

const GoogleLoginButton = ({ disabled }) => {
  const { googleSignIn } = useAuth()
  return (
    <Button
      onClick={googleSignIn}
      startIcon={<GoogleIcon style={{ width: '24px', height: '24px' }}/>}
      variant="outlined"
      color="primary"
      disabled={disabled}
    >
      Sign in with Google
    </Button>
  );
};

export default GoogleLoginButton;
